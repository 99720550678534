.container {
  padding: 40px;
  gap: 10px;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  max-width: 500px;
  /* height: 100vh; */
  box-sizing: border-box;
  min-height: 110vh;
}

.footer {
  position: absolute;
  bottom: 118px;
  margin: 0 -20px;
  width: calc(100% - 40px);
  z-index: 999;
}

.footerTitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 300;
  margin-right: 5px;
  display: inline-block;
}

.cloutflow {
  color: var(--primaryTextColor);
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
}

.completedTitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.completedTitle a {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
