.main {
  display: flex;
  flex-direction: column;
}

.paragraph {
  font-size: 26px;
  font-weight: 700;
}

.secondaryText {
  color: var(--secondaryColor);
  font-size: 14px;
  font-weight: 600;
}

.mainBody {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.homeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  max-width: 500px;
  padding: 20px 40px;
}

.textBody {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.g10 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
}

.p20 {
  padding: 10px 0;
}

.optionHeading {
  color: rgba(255, 255, 255, 0.9);
  font-size: 22px;
  font-weight: 600;
}
.options {
  display: flex;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.disabled {
  color: rgba(255, 255, 255, 0.1);
}

.fbButton {
  margin-top: 20px;
  background: #1877f2;
  height: 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  border-radius: 10px;
}

.loading {
  cursor: not-allowed;
  opacity: 0.7;
}
