.main {
  display: flex;
  flex-direction: column;
}
.headerMain {
  display: flex;
  box-sizing: border-box;
  padding: 40px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.headerLogo {
  height: 40px;
}

.profile {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #1f2b32;
}

.text {
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mainBody {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.homeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  max-width: 500px;
  padding: 20px 40px;
}

.textBody {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
