.container {
  display: grid;
  gap: 20px;
}

.heading {
  color: var(--textColor);
  font-size: 22px;
  font-weight: 700;
}

.subHeading {
  color: var(--textColor);
  font-size: 18px;
  font-weight: 400;
  display: grid;
  gap: 20px;
}

.textContainer {
  display: grid;
  gap: 20px;
}

.listItem {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 600;
}

.link {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}

.fbButton {
  margin-top: 20px;
  background: #1877f2;
  height: 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  border-radius: 10px;
}

.loading {
  cursor: not-allowed;
  opacity: 0.7;
}
