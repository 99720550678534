.heading {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-size: 26px;
  font-weight: 700;
  color: var(--textColor);
}

.card {
  padding: 20px;
  border-radius: 10px;
  background: #1f2b32;
}

.image {
  height: 70px;
  width: 70px;
  border-radius: 100px;
}

.username {
  color: rgba(255, 255, 255, 0.8);
  font-size: 22px;
  font-weight: 500;
}
