.heading {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 600;
}

.subLabel {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 20px;
}

.meta {
  display: inline-block;
  margin-right: 5px;
  width: 24px;
  height: 13px;
}

.connectDesc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.link {
  cursor: pointer;
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  display: block;
  text-decoration: none;
}

.accountContainer {
  display: grid;
  gap: 15px;
  margin-top: 20px;
}

.bold {
  font-weight: 700;
  cursor: pointer;
  text-decoration-line: underline;
}

.addAccount {
  display: flex;
  gap: 5px;
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}

.platformContainer {
  display: grid;
  gap: 15px;
  padding-left: 5px;
}

.platformLabel {
  color: var(--primaryTextColor);
  font-size: 14px;
  font-weight: 800;
}
