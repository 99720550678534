.fbButton {
  /* margin-top: 20px; */
  background: #1877f2;
  height: 60px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  border-radius: 10px;
}

.loading {
  cursor: not-allowed;
  opacity: 0.7;
}
