.App {
  text-align: center;
}

p {
  margin: 0;
}

/* .cccSelector .ant-select-selector {
  border-radius: 12px !important;
  padding: 8px 24px !important;
  height: 56px !important;
  background-color: #533af51a !important;
  color: #080bf4 !important;
}

.cccSelector .ant-select-selection-search-input {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 0 8px !important;
}

.cccSelector .ant-select-arrow {
  color: #080bf4 !important;
} */

.cccSelect.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 60px !important;
  align-items: center !important;
  background-color: transparent;
  border: none;
}

.ant-select-item-option {
  color: black !important;
}

/* .ant-modal,
.ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
}

.ant-modal-header,
.ant-modal-close {
  display: none;
}
.ant-modal-body {
  height: 100%;
} */
