.heading {
  color: var(--textColor);
  font-size: 18px;
  font-weight: 700;
}

.subHeading {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 400;
}

.listItem {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 600;
}

.link {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
}

.accountList {
  display: grid;
  gap: 20px;
  list-style: none;
  margin: 0 !important;
  padding: 0;
}

.accountList li::before {
  content: "\2022";
  color: var(--textColor);
  font-weight: bold;
  display: inline-block;
  font-size: 25px;
  width: 25px;
  margin-left: 0em;
}

.bold {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
}
