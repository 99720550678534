.container {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid var(--textColor);
  color: var(--textColor);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.selected {
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}
