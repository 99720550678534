.headerMain {
  display: flex;
  box-sizing: border-box;
  padding: 40px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.headerLogo {
  height: 40px;
}

.profile {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #1f2b32;
}

.text {
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menuWrapper {
  margin: 40px 20px;
}

.menuWrapperList {
  background-color: #1f2b32;
  border-radius: 12px;
  color: #ffffffcc;
}

.menuWrapperList ul {
  padding: 0px;
}

.menuItem {
  display: flex;
  min-height: 60px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #171f2a;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.menuItem:hover {
  opacity: 0.75;
  background-color: #171f2a50;
}

.menuItemDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuItemText {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footerText {
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 40px 20px;
}

.footerTextBold {
  font-weight: 700;
}

.footerTextLink {
  color: #533af5;
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
}

.emptyHeader {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.emptyText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pref_container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #1f2b32;
}

.pref_title {
  color: var(--primaryTextColor);
  font-size: 16px;
  font-weight: 600;
}

.pref_listItem {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
  width: 80%;
}

.username {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.platform {
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.acc_header {
  border-radius: 10px;
  background: #1f2b32;
  display: flex;
  width: 100%;
  height: 90px;
  padding: 12px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

.acc_image {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.acc_name {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.communityHeader {
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.communityHeader span {
  color: #533af5;
}

.welcomeContainer {
  margin: 0 auto;
  padding: 40px;
  margin-top: 100px;
}

.loginContainer {
  position: relative;
  height: 75vh;
  padding: 0 40px;
  margin-top: 100px;
}

.phoneLabel {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formH5 {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.formH6 {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otpLabel {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.footer {
  position: absolute;
  bottom: 20px;
  margin: 0 -20px;
  width: calc(100% - 40px);
  z-index: 999;
}

.footerTitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 300;
  margin-right: 5px;
  display: inline-block;
}

.cloutflow {
  color: var(--primaryTextColor);
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
  font-weight: 600;
  line-height: normal;
}

.formP {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.btn {
  background-color: var(--primaryColor);
  border: none;
  height: 60px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  color: "#fff";
}

.accountIssueWrapper {
  border-radius: 10px;
  background: var(--primaryColor);
  padding: 20px;
  margin-block: 20px;
  cursor: pointer;
}

.accountProfile {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.accountIssueDesc {
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.connectDesc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.bold {
  font-weight: 700;
  cursor: pointer;
  text-decoration-line: underline;
}
