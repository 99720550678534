.container {
  display: grid;
  padding: 20px;
  gap: 20px;
  background: #273137;
}

.title {
  color: var(--primaryTextColor);
  font-size: 16px;
  font-weight: 600;
}

.tileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressContainer {
  background: #1f2b32;
}

.disabledTitle {
  color: rgba(255, 255, 255, 0.5);
}
