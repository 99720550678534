.heading {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-size: 26px;
  font-weight: 700;
  color: var(--textColor);
}

.label {
  font-size: 12px;
  font-weight: 500;
  /* margin-bottom: 20px !important; */
  color: rgba(255, 255, 255, 0.6);
}

.headingLabel {
  margin-top: 40px !important;
  margin-bottom: 10px !important;
  color: var(--textColor);
  font-size: 22px;
  font-weight: 600;
}

.accountContainer {
  padding: 20px;
  display: grid;
  gap: 25px;
}

.footer {
  color: var(--textColor);
  font-size: 16px;
  font-weight: 500;
}

.footer span {
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
}
