.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 2.5em;
  border-radius: 8px;
  border: 1px solid #4a5967;
  background-color: #1f2b32;
}

.main.focused {
  display: flex;
  height: 2.5em;
  width: 2.5em;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #1f2b32;
}

.input {
  background: none;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;
}

.input:focus {
  background: none;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;
}

.input:autofill {
  background: none !important;
}
