.main {
  display: flex;
  box-sizing: border-box;
  height: 60px !important;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
}

.main:hover {
  display: flex;
  box-sizing: border-box;
  height: 60px !important;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);
}

.text {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.circle {
  background-color: #272727;
  height: 38px;
  width: 38px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: height 0.3s ease, width 0.3s ease;
}

.circle:hover {
  background-color: #272727;
  height: 40px;
  width: 40px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle:active {
  background-color: #272727;
  height: 40px;
  width: 40px;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
