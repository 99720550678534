.link {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.desc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.container {
  display: grid;
  gap: 20px;
}
