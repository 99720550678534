.sendConfirm {
  color: rgba(255, 255, 255, 0.8);

  font-size: 14px;

  font-weight: 400;
}

.sendConfirm span {
  font-weight: 600;
}

.subLabel {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
}

.resend {
  color: var(--primaryColor);
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
  color: rgb(250, 250, 250);
}

.inputContainer {
  display: flex;
  height: 60px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #4a5967;
  background-color: #1f2b32;
  position: relative;
}

.input {
  /* transition: padding-top 0.5s ease; */
  background: none;
  height: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;
  padding-left: 15px;
}

/* .input:focus {
  background: none;
  height: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;

  padding-left: 15px;
} */

.input:autofill {
  background: none !important;
}

.platformContainer {
  display: grid;
  gap: 15px;
  padding-left: 5px;
}

.platformLabel {
  color: var(--primaryTextColor);
  font-size: 14px;
  font-weight: 800;
}

.platformRadio {
  color: var(--primaryTextColor);
  font-size: 14px;
  font-weight: 600;
}
