.container {
  position: relative;
  padding: 40px 10px 10px 10px;
  border-radius: 8px;
  background: #1f2b32;
  height: max-content;
}

.profilePic {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -30px;
  left: calc(50% - 30px);
}

.bName {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 600;
}

.title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 500;
}

.button {
  height: 60px !important;
  padding: 10px !important;
}

.grid {
  display: grid;
  gap: 20px;
}

.heading {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.name {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 400;
}

.listItem {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 400;
}

.space {
  display: flex;
  gap: 5px;
  align-items: center;
}

.link {
  color: var(--primaryColor);
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  margin-bottom: 15px;
  cursor: pointer;
}
