.footerTitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 300;
}

.cloutflow {
  color: var(--textColor);
  font-size: 10px;
  font-weight: 500;
}

.completedTitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}

.completedTitle a {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
