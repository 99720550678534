.main {
  display: flex;
}

.rightMain {
  width: 100%;
  background-color: aliceblue;
}

.mainImage {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.leftMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerMain {
  display: flex;
  padding: 40px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.headerLogo {
  height: 40px;
}

.body {
  display: flex;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
}

.textBody {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}

.innerBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}

.text2 {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.resendText {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
}

@keyframes changeColor {
  0% {
    color: grey;
  }
  100% {
    color: #25d366;
  }
}

.whatsapp {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  animation-name: changeColor;
  animation-duration: 30s;
  animation-iteration-count: 1;
}

.whatsapEnabled {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  color: #25d366;
}
