.main {
  display: flex;
  height: 60px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #4a5967;
  background-color: #1f2b32;
  position: relative;
}

.main.focused {
  display: flex;
  height: 60px;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid #ffffff;
  background-color: #1f2b32;
  position: relative;
}

.helpText {
  color: transparent;
  position: absolute;
  font-size: 10px;
  top: -10px;
  left: 15px;
  transition: all 0.4s ease;
  background: transparent;
  padding: 1px 4px;
}

.main.focused .helpText {
  color: white;
  position: absolute;
  font-size: 10px;
  top: -8px;
  left: 15px;
  background: linear-gradient(to bottom, #1f2b32 50%, #1f2b32 50%);
  transition: all 0.4s ease;
  padding: 1px 4px;
}

.input {
  transition: padding-top 0.5s ease;
  background: none;
  height: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;
  padding-left: 15px;
}

.input:focus {
  background: none;
  height: 60px;
  border: none;
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: 400;
  outline: none;
  padding-left: 15px;
}

.input:autofill {
  background: none !important;
}

.red {
  border: 1px solid #f44336;
}

.errorMessage {
  color: #f44336;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 10px;
  padding-left: 5px;
}
