.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #1f2b32;
}

.title {
  color: var(--primaryTextColor);
  font-size: 16px;
  font-weight: 600;
}

.listItem {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 500;
  width: 80%;
}
