.container {
  padding: 15px 20px;
  border-radius: 10px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.selected {
  color: #533af5;
  border: 2px solid #533af5;
}

.disabled {
  cursor: not-allowed;
  border: 2px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  text-shadow: none;
  box-shadow: none;
}

.error {
  border: 2px solid rgba(234, 67, 53, 0.5);
  color: rgba(234, 67, 53, 0.5);
}

.errorText {
  color: rgba(234, 67, 53, 0.5);
  font-size: 10px;
  font-weight: 400;
  margin-top: 20px;
}
.loading {
  cursor: not-allowed;
}

.button {
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid;
}

.button:disabled {
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  opacity: 0.2;
}
