.container {
  margin: 0 auto;
  background: #1f2b32;
  padding-block: 150px;
  display: grid;
  gap: 50px;
  max-width: 500px;
  height: 70vh;
  /* width: 100%; */
}

.detailContainer {
  display: grid;
  gap: 10px;
}

.profilePic {
  width: 60px;
  height: 60px;
  border-radius: 100px;
}

.bName {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 600;
}

.detailTitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
}

.name {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 400;
}

.listItem {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 400;
}

.descContainer {
  display: grid;
  gap: 20px;
}

.desc {
  color: var(--textColor);
  font-size: 16px;
  font-weight: 500;
}

.link {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.subDesc {
  color: var(--textColor);
  font-size: 14px;
  font-weight: 300;
}

.footerTitle {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 300;
}

.cloutflow {
  color: var(--textColor);
  font-size: 10px;
  font-weight: 500;
  margin-top: 1.5px;
}

.space {
  display: flex;
  align-items: center;
  gap: 5px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
