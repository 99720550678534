.modalHeader {
  display: flex;
  padding: 40px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding-bottom: 0;
}

.modalBody {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}
