.main {
  display: flex;
  padding: 40px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
}

.back {
  color: white;
  font-size: 30px;
}
