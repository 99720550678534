.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #1f2b32;
}

.option {
  display: flex;
  height: 60px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.option:hover {
  display: flex;
  height: 60px;
  padding: 9px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: #223642;
  border-radius: 10px;
}

.optionLeft {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}

.optionText {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 500;
}
.rightIcon {
  width: 24px;
  height: 24px;
  color: white;
}

.icon {
  height: 22px;
  color: white;
}

.li {
  width: 100%;
  height: 1px;
  background-color: #171f2a;
}
