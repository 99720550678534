.wrapper {
  padding: 20px;
}

.emptyHeader {
  color: rgba(255, 255, 255, 0.9);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.menuWrapper {
  margin: 40px 20px;
}

.menuItem {
  display: flex;
  min-height: 60px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #171f2a;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

.menuItem:hover {
  opacity: 0.75;
  background-color: #171f2a50;
}

.menuItemDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.username {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.emptyText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bold {
  font-weight: 700;
  cursor: pointer;
  text-decoration-line: underline;
}

.acc_header {
  border-radius: 10px;
  background: #1f2b32;
  display: flex;
  width: 100%;
  height: 90px;
  padding: 12px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

.acc_image {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.acc_name {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.connectDesc {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 300;
}
